// import { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from "react-router-dom";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from "./utils/NavigationClient";

import EmailSignature from './Pages/EmailSignature/EmailSignature';
import './App.scss';
import {FaFileContract} from 'react-icons/fa';

// Msal imports
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
// import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
// import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from './authConfig'

import { ErrorComponent } from './ui-components/ErrorComponent'
import { Loading } from './ui-components/Loading'




function App({ pca }) {

  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <Router>
        <div className="pageNav">
          {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
          <Switch>
            <Route exact path="/">
              {/* <Home /> */}
              <EmailSignature />
              
            </Route>
            <Route path="/emailSignature">
              <EmailSignature />
            </Route>
          </Switch>
        </div>
      </Router>
    </MsalProvider>
  );
}

export default App;

function Home() {

  const authRequest = {
    ...loginRequest
  };

  return (
    <>
      <MsalAuthenticationTemplate
        // interactionType={InteractionType.Popup}
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
        errorComponent={ErrorComponent}
        loadingComponent={Loading}
      >
        {/* <HomeContent /> */}


        <AuthenticatedTemplate>

          <div className="App">

            <div className="header">
              <div className="header-container">
                <h1>Tech4S</h1>
                <h2>Internal Tools</h2>
                {/* <h3>Hello {username}!</h3> */}
              </div>
            </div>

            <div className="app-inner flex-center">

              <Link to="/emailSignature" className="home-item email-signature">
                {/* <i className="fas fa-file-contract"></i> */}
                {/* <FontAwesomeIcon icon={faFileContract} className='fas' /> */}
                <FaFileContract className="fas" />
                Email Signature
              </Link>
            </div>
          </div>
        </AuthenticatedTemplate>
      </MsalAuthenticationTemplate>
      <UnauthenticatedTemplate>
        <center>Please sign-in to see your profile information.</center>
      </UnauthenticatedTemplate>
    </>
  );
}





// // Sample app imports
// import { PageLayout } from "./ui-components/PageLayout";
// import { Home } from "./pages/Home";
// import { Profile } from "./pages/Profile";
// import { Logout } from "./pages/Logout";

// // Class-based equivalents of "Profile" component
// import { ProfileWithMsal } from "./pages/ProfileWithMsal";
// import { ProfileRawContext } from "./pages/ProfileRawContext";

// function App({ pca }) {
//   // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
//   const history = useHistory();
//   const navigationClient = new CustomNavigationClient(history);
//   pca.setNavigationClient(navigationClient);

//   return (
//     <MsalProvider instance={pca}>
//       <PageLayout>
//         <Grid container justifyContent="center">
//           <Pages />
//         </Grid>
//       </PageLayout>
//     </MsalProvider>
//   );
// }

// function Pages() {
//   return (
//     <Switch>
//       <Route path="/profile">
//         <Profile />
//       </Route>
//       <Route path="/profileWithMsal">
//         <ProfileWithMsal />
//       </Route>
//       <Route path="/profileRawContext">
//         <ProfileRawContext />
//       </Route>
//       <Route path="/logout">
//         <Logout />
//       </Route>
//       <Route path="/">
//         <Home />
//       </Route>
//     </Switch>
//   )
// }

// export default App;