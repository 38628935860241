import { LogLevel } from "@azure/msal-browser";
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check

// const ua = window.navigator.userAgent;
// const msie = ua.indexOf("MSIE ");
// const msie11 = ua.indexOf("Trident/");
// const msedge = ua.indexOf("Edge/");
// const firefox = ua.indexOf("Firefox");
// const isIE = msie > 0 || msie11 > 0;
// const isEdge = msedge > 0;
// const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        // clientId: "d4d6f697-7a0d-4713-96a2-16d99a70dcae", - OLD
        clientId: "47566cce-18ea-41b5-91e0-045eb3cac409",
        authority: 'https://login.microsoftonline.com/tech4sltd.onmicrosoft.com'
        // https://login.microsoftonline.com/fa6a5c2a-bd4b-4072-84ea-4dc3af2888f2/saml2
        //authority: "https://login.microsoftonline.com/fa6a5c2a-bd4b-4072-84ea-4dc3af2888f2/oauth2/authorize",
        //redirectUri: "https://tools.river.tech/",
        //postLogoutRedirectUri: "/"
        
        
        //authority: "https://login.microsoftonline.com/common",
        //clientId: "8e9b127c-0951-432c-8793-5c74e5e78d6f",
        // //authority: "https://login.windows-ppe.net/common",
        //authority: "https://login.windows-ppe.net/common",
        //authority: "https://login.microsoftonline.com/riverigaming.onmicrosoft.com",
        //authority: "https://riverigaming.onmicrosoft.com",
        // authority: 'fa6a5c2a-bd4b-4072-84ea-4dc3af2888f2',
        // redirectUri: "/",
        // responseMode: "query",
        // responseType: "code"
    },
    cache: {
        cacheLocation: "localStorage",
        //storeAuthStateInCookie: isIE || isEdge || isFirefox
        storeAuthStateInCookie: false
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};
//     system: {
//         loggerOptions: {
//             loggerCallback: (level, message, containsPii) => {
//                 if (containsPii) {	
//                     return;	
//                 }
//                 switch (level) {	
//                     case LogLevel.Error:	
//                         console.error(message);	
//                         return;	
//                     case LogLevel.Info:	
//                         console.info(message);	
//                         return;	
//                     case LogLevel.Verbose:	
//                         console.debug(message);	
//                         return;	
//                     case LogLevel.Warning:	
//                         console.warn(message);	
//                         return;	
//                     default:
//                         return;
//                 }
//             }
//         }
//     }
// };

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"]
};